import React, { useEffect } from 'react'
import image from '../../Images/1698929667_c2907cd0bdf6973ac1f1.jpeg'
import About from '../About/About'
import Whatwedo from '../Whatvedo/Whatwedo'
import Knowsection from '../Knowsection/Knowsection'
import Twocard from '../Twocard/Twocard'
import PhotoGallery from '../Photogallery/PhotoGallery'
const Home = () => {
    useEffect(()=>{
        window.scrollTo({
          top:0,
          behavior:"smooth"
        })
      },[])
    return (
        <>
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={image} class="d-block w-100" alt="..." />
                    </div>
                    <div class="carousel-item">
                        <img src={image} class="d-block w-100" alt="..." />
                    </div>
                    <div class="carousel-item">
                        <img src={image} class="d-block w-100" alt="..." />
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>

            <About />
            <Whatwedo />
            <Knowsection />
            <Twocard />
            <PhotoGallery />
        </>
    )
}

export default Home